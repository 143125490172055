@import '../../styles/all';

.Link {
  color: $link;
  font-weight: $weight-bold;
  text-decoration: none;
  border-bottom: 1px solid rgba($link, 0.2);

  &:hover {
    color: $link-hover;
    border-bottom: 1px solid rgba($link, 0.4);
  }
}