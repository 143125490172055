@import '../../styles/all';

.Face {
  width: 144px;
  height: 185px;
  display: block;
  background-image: url('../../assets/images/jonno.png');
  background-size: 299px 185px;
  box-shadow: $box-shadow;
  position: fixed;
  top: 0;
  z-index: 2000;
  transform-origin: top left;
  transition: box-shadow 0.3s ease, transform 0.3s ease, top 0.1s ease;

  &.has-scrolled {
    transform: scale(0.7);

    &:hover {
      background-position-x: -150px;
      box-shadow: $box-shadow-hover;
      cursor: n-resize;
      transform: scale(0.72);
    }

    &:active {
      top: -6px;
    }
  }
}