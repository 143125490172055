// COLORS

$base: black;
$title: $base;
$subtitle: #7F7F7F;
$text: #313131;
$link: #77508C;
$link-hover: #42205F;


// SIZES
$size: 1rem; // 16px
$size-medium: 1.5rem; // 24px
$size-big: 1.625rem; // 28px
$size-large: 2rem; // 32px

$gutter: $size-medium; // 16px

// FONT
// weight
$weight-thin: 300;
$weight-regular: 400;
$weight-bold: 600;

// height
$font-height: 1;

// sizes
$font-size: 16px;

// TITLES
$title-size: $size-medium;
$title-weight: $weight-thin;
$title-height: 1.2;

// PARAGRAPH
$paragraph-size: $size-big;
$paragraph-height: 1.5;


// SHADOW
$box-shadow: 0 4px 28px 0 rgba(0,0,0,0.11);
$box-shadow-hover: 0 4px 40px 0 rgba(0,0,0,0.3);

// RESPONSIVE
$column-gap: $gutter/2 !default; // 24px
// The container horizontal gap, which acts as the offset for breakpoints
$gap: 64px !default;
// 960, 1152, and 1344 have been chosen because they are divisible by both 12 and 16
$tablet: 769px !default;
// 960px container + 4rem
$desktop: 960px + (2 * $gap) !default;
// 1152px container + 4rem
$widescreen: 1152px + (2 * $gap) !default;
$widescreen-enabled: true !default;
// 1344px container + 4rem
$fullhd: 1344px + (2 * $gap) !default;
$fullhd-enabled: true !default;